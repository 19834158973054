















import Vue from 'vue';

export default Vue.extend({
  name: 'Tablet',
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      resizeWatcher: null
    }
  },
  methods: {
    show () {
      this.active = true
    },
    hide () {
      this.active = false
      this.$emit('hide')
    },
    clickBackdrop () {
      if (!this.preventHide) this.hide()
    },
    emitSize () {
      this.$emit('resize', {
        height: this.$refs.innerDiv.clientHeight,
        width:  this.$refs.innerDiv.clientWidth
      })
    }
  },
  mounted () {
    this.active = this.startActive
    this.emitSize()
    window.addEventListener('resize', this.emitSize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.emitSize)
  }
});
