








import Vue from 'vue';
export default Vue.extend({
  name: 'Curtain',
  props: {
    heading: String,
    active: Boolean,
    delay: {
      type: Number,
    }
  },
  data () {
    return {
      skipTransition: false,
      altMode: false
    }
  },
  methods: {
    emitClick () {
      this.$emit('click')
    }
  },
  watch: {
    // active (newVal, oldVal) {
    //   console.log('Curtain.watch.active', oldVal, newVal)
    //   // after hiding, switch to alt mode
    //   if (oldVal && !newVal) {
    //     window.setTimeout(()=>{
    //       console.log('  alt-mode activated')
    //       this.skipTransition = true
    //     }, 2000)
    //     window.setTimeout(()=>{
    //       this.altMode = true
    //     }, 2001)
    //     window.setTimeout(()=>{
    //       this.skipTransition = false
    //     }, 4000)
    //   }
    // }
  }
});
